export const supportedDeviceTypes = [
  {
    deviceType: "action.devices.types.LIGHT",
    name: "Light",
    recommendedTraits: [
      "action.devices.traits.Brightness",
      "action.devices.traits.ColorSetting",
      "action.devices.traits.OnOff",
    ],
  },
  {
    deviceType: "action.devices.types.OUTLET",
    name: "Outlet",
    recommendedTraits: ["action.devices.traits.OnOff"],
  },
  {
    deviceType: "action.devices.types.SENSOR",
    name: "Sensor",
    recommendedTraits: [
      "action.devices.traits.OnOff",
      "action.devices.traits.SensorState",
      "action.devices.traits.HumiditySetting",
      "action.devices.traits.TemperatureControl",
      "action.devices.traits.OpenClose",
      "action.devices.traits.Brightness",
    ],
  },
  {
    deviceType: "action.devices.types.SECURITYSYSTEM",
    name: "Security System",
    recommendedTraits: [
      "action.devices.traits.ArmDisarm",
      "action.devices.traits.StatusReport",
    ],
  },
];
