import React, { useState } from "react";
import { Card, Skeleton, Avatar, Input, Form, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  EllipsisOutlined,
  SwitcherOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

const GroupCard = ({ group, onUpdate, onDelete, onShowDevices, count }) => {
  const [editing, setEditing] = useState(false);
  const [devicesModalVisible, setDevicesModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { id, name } = group;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onUpdate(values);
      setEditing(false);
    } catch (error) {
      //console.log("ERROR>>", error);
    }
  };

  const handleDelete = async () => {
    try {
      const values = await form.validateFields();
      onDelete({ id: values.id });
      setEditing(false);
    } catch (error) {
      //console.log("ERROR>>", error);
    }
  };

  return (
    <>
      <Form
        onFinish={handleSave}
        initialValues={{ name: name, id: id }}
        form={form}
      >
        <Card
          key={id}
          actions={[
            editing ? (
              <Tooltip placement="bottom" title={"Save"}>
                <SaveOutlined
                  htmlType="submit"
                  onClick={handleSave}
                  key="save"
                />
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title={"Edit"}>
                <EditOutlined onClick={handleEdit} key="edit" />
              </Tooltip>
            ),
            <Tooltip placement="bottom" title={"Devices"}>
              <SwitcherOutlined
                onClick={() => onShowDevices(group)}
                key="devices"
              />
            </Tooltip>,
            <Tooltip placement="bottom" title={"Delete"}>
              <DeleteOutlined onClick={handleDelete} key="delete" />
            </Tooltip>,
          ]}
        >
          <Form.Item name="id" noStyle style={{ display: "none" }}></Form.Item>
          <Skeleton loading={false} active>
            <Meta
              //   avatar={
              //     <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              //   }
              title={
                editing ? (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter the group name!",
                      },
                    ]}
                    name="name"
                    // noStyle
                  >
                    <Input autoComplete={"off"} placeholded="Group name" />
                  </Form.Item>
                ) : (
                  name
                )
              }
              description={editing ? null : `${count} devices in group`}
            />
          </Skeleton>
        </Card>
      </Form>
      {/* 
      <Modal
        title="Devices"
        getContainer={false}
        visible={devicesModalVisible}
        onOk={form.submit}
        confirmLoading={loading}
        onCancel={onClose}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            name="addDevice"
            onFinish={doSubmit}
            layout="vertical"
            //   initialValues={formInitialValues()}
            hideRequiredMark
          >
            <Form.Item
              name="id"
              label="Enter enter the code printed on the device"
              rules={[{ required: true, message: "Please enter the code" }]}
            >
              <Input autoFocus placeholder="Enter the code" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal> */}
    </>
  );
};

export default GroupCard;
