import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Divider,
  message,
  Spin,
} from "antd";

import {
  UserOutlined,
  LockOutlined,
  GoogleOutlined,
  MailOutlined,
} from "@ant-design/icons";

import { registerWithEmail, loginWithGoogle } from "../services/auth";
import UserContext from "../helpers/userContext";

const RegisterPage = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const { user, reloadUserDetails } = useContext(UserContext);
  const onFinish = async ({ email, password, firstname, lastname }) => {
    setLoading(true);
    try {
      await registerWithEmail({
        email,
        password,
        firstname,
        lastname,
      });
      message.success("Register successful");
      reloadUserDetails();
      history.replace("/");
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignin = async () => {
    setLoading(true);
    try {
      await loginWithGoogle();
      message.success("Register successful");
      history.replace("/");
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    //console.log("Failed:", errorInfo);
  };

  return (
    <Row justify="center">
      <Col xs={24} md={14} lg={10} xl={8} xxl={6}>
        <Card title="Register">
          <Spin spinning={loading}>
            <Form
              name="register"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="medium"
            >
              <Form.Item
                noStyle
                rules={[{ required: true, message: "Firstname is required." }]}
              >
                <Form.Item
                  hasFeedback
                  name="firstname"
                  rules={[
                    { required: true, message: "Firstname is required." },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 5px)",
                    marginRight: 8,
                  }}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Firstname"
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  name="lastname"
                  rules={[{ required: true, message: "Lastname is required." }]}
                  style={{ display: "inline-block", width: "calc(50% - 5px)" }}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Lastname"
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item
                hasFeedback
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required.",
                  },
                  {
                    type: "email",
                    message: "Email is not valid.",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  autoComplete="username"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                hasFeedback
                rules={[{ required: true, message: "Password is required." }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  autoComplete="new-password"
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                hasFeedback
                rules={[
                  { required: true, message: "Please confirm your Password." },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords don't match.");
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  autoComplete="new-password"
                  type="password"
                  placeholder="Confirm password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Sign up
                </Button>
              </Form.Item>
              <Divider>OR</Divider>
              <Form.Item>
                <Button
                  danger
                  block
                  type="primary"
                  className="login-form-button"
                  icon={<GoogleOutlined />}
                  onClick={handleGoogleSignin}
                >
                  Sign up with Google
                </Button>
              </Form.Item>
              <Divider>
                <span>
                  Already have an account? <Link to="/login">Sign in now</Link>
                </span>
              </Divider>
            </Form>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default RegisterPage;
