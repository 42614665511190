import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, message, Button, Empty } from "antd";

import UserContext from "../helpers/userContext";
import CreateGroupForm from "./createGroupForm";
import GroupCard from "./groupCard";
import DevicesInGroup from "./devicesInGroup";

import {
  createGroup,
  deleteGroup,
  editGroup,
  getUserGroups,
} from "../services/group";

import { attachDevicesToGroup } from "../services/device";

import { getDevices, editDevice, getUserDevices } from "../services/device";

const Groups = (props) => {
  const { user } = useContext(UserContext);
  const [groups, setGroups] = useState([]);
  const [devices, setDevices] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showingGroup, setShowingGroup] = useState(null);

  const [createGroupFormOpen, setCreateGroupFormOpen] = useState(false);
  const [createGroupFormLoading, setCreateGroupFormLoading] = useState(false);

  const handleCreateGroup = async ({ name }) => {
    setCreateGroupFormLoading(true);
    try {
      await createGroup(user.uid, name);
      setCreateGroupFormOpen(false);
      setCreateGroupFormLoading(false);
      message.success("Group created successfully");
    } catch (error) {
      setCreateGroupFormLoading(false);
      message.error(error.message);
    }
  };

  const handleUpdateGroup = async ({ id, name }) => {
    // throw { message: "Test Error Message" };
    const uid = user.uid;
    try {
      await editGroup(uid, id, name);
      message.success("Group updated successfully");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleDeleteGroup = async ({ id }) => {
    // throw { message: "Test Error Message" };
    const uid = user.uid;
    try {
      await deleteGroup(uid, id);
      message.success("Group deleted successfully");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleShowDevices = (group) => {
    //console.log(group);
    setModalLoading(false);
    setShowingGroup(group);
    setModalVisible(true);
  };

  const handleUpdateGroupDevices = async ({ group, devices }) => {
    try {
      setModalLoading(true);
      await attachDevicesToGroup(devices, group.id, user.uid);
      setModalVisible(false);
      message.success("Updated successfully");
    } catch (error) {
      message.error(error.message);
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribeFromGroups = getUserGroups(user.uid, (data) => {
      // //console.log(data);
      setGroups(data);
    });

    const unsubscribeFromDevices = getUserDevices(user.uid, (data) => {
      //console.log("DEVICES>>", data);
      setDevices(data);
    });
    return () => {
      unsubscribeFromGroups();
      unsubscribeFromDevices();
    };
  }, []);

  return (
    <>
      <DevicesInGroup
        visible={modalVisible}
        data={devices}
        onClose={() => {
          setModalVisible(false);
        }}
        loading={modalLoading}
        onUpdate={handleUpdateGroupDevices}
        group={showingGroup}
      />
      <Card
        title="Groups"
        extra={
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setCreateGroupFormOpen(true);
            }}
          >
            Create Group
          </Button>
        }
      >
        <Row gutter={[16, 16]}>
          {(groups.length &&
            groups.map((group) => {
              // //console.log(group);
              return (
                <Col key={group.id} xs={24} md={12} xl={6} xxl={4}>
                  <GroupCard
                    onUpdate={handleUpdateGroup}
                    onDelete={handleDeleteGroup}
                    onShowDevices={handleShowDevices}
                    count={
                      devices.filter((device) => device.groupId == group.id)
                        .length || 0
                    }
                    group={group}
                  />
                </Col>
              );
            })) || (
            <Col xs={24}>
              <Empty
                description={"No Group"}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              >
                <Button
                  onClick={() => {
                    setCreateGroupFormOpen(true);
                  }}
                  type="primary"
                >
                  Create Now
                </Button>
              </Empty>
            </Col>
          )}
        </Row>
      </Card>

      <CreateGroupForm
        visible={createGroupFormOpen}
        onClose={() => {
          setCreateGroupFormOpen(false);
        }}
        onSubmit={handleCreateGroup}
        loading={createGroupFormLoading}
      />
    </>
  );
};

export default Groups;
