import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Divider,
  Spin,
  message,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import { resetPassword } from "../services/auth";

const ResetPage = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async ({ email }) => {
    setLoading(true);
    try {
      await resetPassword({ email });
      message.success("Email sent successfully");
      history.replace("/login");
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    //console.log("Failed:", errorInfo);
  };

  return (
    <Row justify="center">
      <Col xs={24} md={14} lg={10} xl={8} xxl={6}>
        <Card title="Reset your password">
          <Spin spinning={loading}>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="medium"
            >
              <Form.Item>
                <span>
                  Enter your email and we'll send you instructions to reset your
                  password.
                </span>
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email." },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="login-form-button"
                >
                  Reset
                </Button>
              </Form.Item>
              <Divider>
                <span>
                  Don't have an account? <Link to="/register">Sign up now</Link>
                </span>
              </Divider>
            </Form>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPage;
