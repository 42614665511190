import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute";
import PublicRoute from "./components/publicRoute";

import { Layout, Spin, Row } from "antd";
import "./App.css";
import "antd/dist/antd.css";

import TopHeader from "./components/topHeader";
import Sidebar from "./components/sidebar";
import HomePage from "./pages/homePage";
import LightsPage from "./pages/lightsPage";
import GroupsPage from "./pages/groupsPage";
import SettingsPage from "./pages/settingsPage";
import DevicesPage from "./pages/devicesPage";
import AdminPage from "./pages/admin/adminPage";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/registerPage";
import ResetPage from "./pages/resetPage";
import LogoutPage from "./pages/logoutPage";
import NotFoundPage from "./pages/notFoundPage";

import UserContext from "./helpers/userContext";

import { getCurrentUser } from "./services/auth";
import { getUserDevices } from "./services/device";

import { message } from "antd";
// Messsage Config
message.config({
  duration: 2,
  maxCount: 1,
});

const { Content } = Layout;

class App extends Component {
  state = {
    sidebarCollapsed: false,
    user: null,
    loaded: false,
  };

  populateUserDetails = () => {
    getCurrentUser((user) => {
      this.setState({ user });
      this.setState({ loaded: true });
    });
  };

  componentDidMount = () => {
    this.populateUserDetails();
  };

  handleSidebarToggle = () => {
    const toggleState = this.state.sidebarCollapsed;
    this.setState({
      sidebarCollapsed: !toggleState,
    });
  };

  render() {
    if (!this.state.loaded) {
      return (
        <Spin size="large" spinning={!this.state.loaded}>
          <Row style={{ minHeight: "100vh" }}></Row>
        </Spin>
      );
    }
    return (
      <>
        <UserContext.Provider
          value={{
            user: this.state.user,
            reloadUserDetails: this.populateUserDetails,
          }}
        >
          <Layout style={{ minHeight: "100vh" }}>
            <Sidebar collapsed={this.state.sidebarCollapsed} />
            <Layout className="site-layout">
              <TopHeader
                collapsed={this.state.sidebarCollapsed}
                onSidebarToggle={this.handleSidebarToggle}
              />
              <Content
                style={{
                  margin: "24px 16px",
                  // padding: 24,
                  minHeight: 280,
                }}
              >
                <Switch>
                  <PublicRoute path="/login" component={LoginPage} />
                  <PublicRoute path="/register" component={RegisterPage} />
                  <PublicRoute path="/reset" component={ResetPage} />

                  <Route path="/logout" component={LogoutPage} />

                  <ProtectedRoute path="/lights" component={LightsPage} />
                  <ProtectedRoute path="/devices" component={DevicesPage} />
                  <ProtectedRoute path="/groups" component={GroupsPage} />
                  <ProtectedRoute path="/settings" component={SettingsPage} />
                  <ProtectedRoute
                    onlyAdmin
                    path="/admin"
                    component={AdminPage}
                  />
                  <ProtectedRoute exact path="/" component={HomePage} />
                  {/* <Redirect from="/" to="/home" /> */}
                  {/* <Route path="/" component={NotFoundPage} /> */}
                  <Route component={NotFoundPage} />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </UserContext.Provider>
      </>
    );
  }
}

export default App;
