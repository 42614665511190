import React, { useState, useEffect } from "react";
import { Table, Modal, loading, Spin } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "type",
    dataIndex: "type",
  },
];

const DevicesInGroup = ({
  data,
  group,
  visible,
  onClose,
  onUpdate,
  loading,
}) => {
  const filteredData = data.map((device) => {
    return {
      key: device.id,
      name: device.name.defaultNames[0],
      type: device.type.split(".").pop(),
      disabled: device.groupId && group !== null && group.id != device.groupId,
      selected: device.groupId && group !== null && group.id == device.groupId,
    };
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    //console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    // onUpdate(selectedRowKeys);
  };

  const onSave = () => {
    onUpdate({ group: group, devices: selectedRowKeys });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideDefaultSelections: true,
    //   selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
    getCheckboxProps: (record) => ({
      disabled: record.disabled, // Column configuration not to be checked
      selected: record.selected,
      // name: record.name,
    }),
  };

  useEffect(() => {
    const selectedRows = filteredData
      .filter((data) => data.selected)
      .map((data) => data.key);
    //console.log("selectedRows>>", selectedRows);
    setSelectedRowKeys(selectedRows);
  }, [visible]);

  return (
    <Modal
      title={group && group.name}
      getContainer={false}
      visible={visible}
      onOk={onSave}
      confirmLoading={false}
      onCancel={onClose}
    >
      <Spin spinning={loading}>
        <Table
          title={false}
          tableLayout="auto"
          rowSelection={rowSelection}
          columns={columns}
          pagination={false}
          dataSource={filteredData}
        />
      </Spin>
    </Modal>
  );
};
export default DevicesInGroup;
