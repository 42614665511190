import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { Menu, Layout } from "antd";

import AccountSettings from "./accountSettings";
import UnderDevelopment from "./underDevelopment";

const { Content, Sider } = Layout;

const SettingsPage = () => {
  return (
    <Content>
      <Layout className="site-layout-background" style={{ padding: "24px 0" }}>
        <Sider className="site-layout-background">
          <Menu
            mode="inline"
            selectedKeys={[
              window.location.pathname,
              "/" + window.location.pathname.split("/")[1],
            ]}
            openKeys={["/" + window.location.pathname.split("/")[1]]}
            style={{ height: "100%" }}
          >
            <Menu.Item key="/settings/account">
              <Link to="/settings/account">Basic Settings</Link>
            </Menu.Item>
            <Menu.Item key="/settings/linking">
              <Link to="/settings/linking">Account Linking</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ padding: "0 24px" }}>
          <Switch>
            <Route path="/settings/account" component={AccountSettings} />
            <Route path="/settings/linking" component={UnderDevelopment} />
            <Redirect to="/settings/account" />
          </Switch>
        </Content>
      </Layout>
    </Content>
  );
};

export default SettingsPage;
