import React, { useEffect } from "react";
import { Row, Col, Spin, message } from "antd";
import { logout } from "../services/auth";

const LogoutPage = ({ history }) => {
  useEffect(() => {
    const signOut = async () => {
      try {
        await logout();
        message.success("Logout successful");
      } catch (error) {
        message.error(error.message);
      } finally {
        history.replace("/");
      }
    };
    signOut();
    return () => {};
  }, []);
  return (
    <Spin spinning={true}>
      <Row style={{ minHeight: "50vh" }} justify="center">
        <Col xs={24} md={14} lg={10} xl={8} xxl={6}></Col>
      </Row>
    </Spin>
  );
};

export default LogoutPage;
