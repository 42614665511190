import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import App from "./App";
import Authorization from "./authorization";
import * as serviceWorker from "./serviceWorker";
import { notification } from "antd";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/authorize" component={Authorization} />
      <Route
        path="/"
        render={(props) => {
          //console.log("APP PROPS>><<", props);
          return <App {...props} />;
        }}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
/*
serviceWorker.register({
  onSuccess: () => {
    notification.success({
      message: "Content is cached for offline use.",
    });
  },
});
*/
