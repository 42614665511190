import React, { useState, useEffect } from "react";
import { Input, Form, Spin, Modal } from "antd";

const AddDeviceForm = ({ visible, onClose, onSubmit, loading }) => {
  const [form] = Form.useForm();

  const doSubmit = (values) => {
    //console.log("Do Submit Called");
    onSubmit(values);
  };

  useEffect(() => {
    form.resetFields();
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      title="Add Device"
      getContainer={false}
      visible={visible}
      onOk={form.submit}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="addDevice"
          onFinish={doSubmit}
          layout="vertical"
          //   initialValues={formInitialValues()}
          hideRequiredMark
        >
          <Form.Item
            name="id"
            label="Enter enter the code printed on the device"
            rules={[{ required: true, message: "Please enter the code" }]}
          >
            <Input autoFocus placeholder="Enter the code" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddDeviceForm;
