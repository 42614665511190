import { db } from "./firebase";
import _ from "lodash";
import { flatten, unflatten } from "flat";

export const createGroup = (userId, groupName) => {
  const ref = db().collection(`users/${userId}/groups/`);
  return ref.add({ name: groupName });
};

export const editGroup = (userId, groupId, newName) => {
  const ref = db().doc(`users/${userId}/groups/${groupId}`);
  return ref.update({ name: newName });
};

export const deleteGroup = (userId, groupId) => {
  const ref = db().doc(`users/${userId}/groups/${groupId}`);
  return ref.delete();
};

export const getUserGroups = (userId, callback) => {
  const ref = db().collection(`users/${userId}/groups/`);
  const listener = ref.onSnapshot((docs) => {
    const data = [];
    docs.forEach((doc) => {
      const document = { id: doc.id, ...doc.data() };
      data.push(document);
    });
    callback(data);
  });
  return listener;
};
