import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Divider,
  Spin,
  message,
} from "antd";

import { MailOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";
import { loginWithEmail, loginWithGoogle } from "../services/auth";
import UserContext from "../helpers/userContext";

const { Paragraph } = Typography;

const LoginPage = ({ history, onComplete, user }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    setLoading(true);
    await onComplete();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onCloseWindow = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  return (
    <Row justify="center">
      <Col xs={24} md={14} lg={10} xl={8} xxl={6}>
        <Card title="Permissions to Google">
          <Spin spinning={loading}>
            <Paragraph>
              Google needs permissions to control your devices.
            </Paragraph>
            <Form
              name="auth"
              className="auth-form"
              initialValues={{
                email: user && user.email,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="medium"
            >
              <Form.Item
                name="email"
                disabled
                rules={[
                  { required: true, message: "Please input your Email." },
                ]}
              >
                <Input
                  disabled
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  autoComplete="username"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="auth-form-button"
                >
                  Give Access
                </Button>
              </Form.Item>
              <Divider>OR</Divider>
              <Form.Item>
                <Button
                  danger
                  block
                  type="primary"
                  className="auth-form-button"
                  onClick={onCloseWindow}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPage;
