import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UsersPage from "./usersPage";
import DevicesPage from "./devicesPage";
import UnderDevelopment from "../underDevelopment";

const AdminPage = () => {
  return (
    <Switch>
      <Route path="/admin/users" component={UnderDevelopment} />
      <Route path="/admin/devices" component={DevicesPage} />
      <Redirect exact from="/admin" to="/admin/devices" />
    </Switch>
  );
};

export default AdminPage;
