import React, { Component } from "react";

import { Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import DevicesTable from "./devicesTable";

const tableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Action",
    key: "action",
    render: ({ key }) => {
      //   //console.log(key);
      return (
        <span>
          <a style={{ marginRight: 16 }} href={"#edit/" + key}>
            <EditOutlined />
          </a>
          <a href={"#delete/" + key}>
            <DeleteOutlined />
          </a>
        </span>
      );
    },
  },
];

const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    key: i,
    name: "John Brown",
    email: `${i}2`,
    description: `My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
  });
}

const expandable = { expandedRowRender: (record) => <DevicesTable nested /> };

class UsersTable extends React.Component {
  state = {
    loading: false,
    pagination: { position: "bottom" },
    size: "default",
    expandable,
    showHeader: true,

    hasData: true,
    tableLayout: "fixed",
  };

  render() {
    return (
      <Table
        {...this.state}
        //   tableLayout='auto'
        columns={tableColumns}
        dataSource={this.state.hasData ? data : null}
        scroll={this.state.scroll}
      />
    );
  }
}

export default UsersTable;
