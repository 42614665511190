import React, { useContext } from "react";
import {} from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Badge, Avatar, Empty } from "antd";
import UserContext from "../helpers/userContext";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
  DownOutlined,
  BellOutlined,
  LogoutOutlined,
  DashboardOutlined,
  LinkOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

const userMenu = (isAdmin) => {
  return (
    <Menu>
      {isAdmin && (
        <Menu.Item key="0">
          <Link to="/admin">
            <DashboardOutlined style={{ marginRight: "8px" }} />
            Admin
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="1">
        <Link to="/settings">
          <SettingOutlined style={{ marginRight: "8px" }} />
          Settings
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/settings/linking">
          <LinkOutlined style={{ marginRight: "8px" }} />
          Account Linking
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Link to="/logout">
          <LogoutOutlined style={{ marginRight: "8px" }} />
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const menu = (
  <Menu>
    {/* <Menu.Item key="0">
      <span>
        <SettingOutlined />
        Notification 1
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>
        <SettingOutlined />
        Notification 2
      </span>
    </Menu.Item>
    <Menu.Item key="2">
      <span>
        <SettingOutlined />
        Notification 3
      </span>
    </Menu.Item> */}
    <Menu.Item disabled key="empty">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No Notification"
      />
    </Menu.Item>
  </Menu>
);

const TopHeader = (props) => {
  const { user } = useContext(UserContext);
  //console.log(user);
  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      {React.createElement(
        props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: () => {
            props.onSidebarToggle();
          },
        }
      )}
      {user && (
        <Menu
          selectable={false}
          theme="light"
          style={{ lineHeight: "64px", float: "right" }}
          mode="horizontal"
        >
          <Menu.Item key="1">
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              // trigger={["click"]}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Badge dot={true}>
                  <BellOutlined />
                </Badge>
              </a>
            </Dropdown>
          </Menu.Item>
          <Menu.Item key="2">
            <Dropdown
              overlay={() => userMenu(user.isAdmin)}
              placement="bottomRight"
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {user.photoURL ? (
                  <Avatar size="default" src={user.photoURL} />
                ) : (
                  <Avatar size="small" icon={<UserOutlined />} />
                )}
                {user.name || user.email} <DownOutlined />
              </a>
            </Dropdown>
          </Menu.Item>
        </Menu>
      )}
    </Header>
  );
};

export default TopHeader;
