import React from "react";

import { Card, Typography, List } from "antd";

const { Text } = Typography;

const activities = [
  // {
  //   title: "Front door",
  //   description: "Front door opened.",
  // },
];
const RecentActivities = () => {
  return (
    <Card title="Recent activities">
      <List
        itemLayout="horizontal"
        dataSource={activities}
        renderItem={(item) => (
          <List.Item actions={[<Text>12:00 PM</Text>]}>
            <List.Item.Meta
              title={<a href="#">{item.title}</a>}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default RecentActivities;
