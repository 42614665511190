import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import UserContext from "../helpers/userContext";
import { message } from "antd";

const ProtectedRoute = ({
  path,
  component: Component,
  onlyAdmin,
  render,
  ...rest
}) => {
  const { user } = useContext(UserContext);
  //console.log(user);
  if (user) {
    if (onlyAdmin && !user.isAdmin) {
      message.error("You are not allowed.");
      return <Redirect to="/" />;
    }
    return (
      <Route
        {...rest}
        render={(props) => {
          return Component ? <Component {...props} /> : render(props);
        }}
      />
    );
  }
  return <Redirect to="/login" />;
};

export default ProtectedRoute;
