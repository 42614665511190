import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { BranchesOutlined } from "@ant-design/icons";

const UnderDevelopment = () => {
  return (
    <Result
      icon={<BranchesOutlined />}
      title="Feature in Development."
      extra={
        <Link to="/">
          <Button type="primary">Go Home</Button>
        </Link>
      }
    />
  );
};

export default UnderDevelopment;
