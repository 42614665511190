import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import UserContext from "../helpers/userContext";
import { message } from "antd";

const PublicRoute = ({ path, component: Component, render, ...rest }) => {
  const { user } = useContext(UserContext);
  //console.log(user);
  if (!user) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return Component ? <Component {...props} /> : render(props);
        }}
      />
    );
  }
  return <Redirect to="/" />;
};

export default PublicRoute;
