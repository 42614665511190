import React, { useState, useEffect } from "react";

import DevicesTable from "../../components/admin/devicesTable";
import { Card, Button, Row, Col, message } from "antd";
import DeviceDetailsForm from "../../components/admin/deviceDetailsForm";
import DeviceForm from "../../components/admin/deviceForm";

import {
  createDevice,
  getDevices,
  editDevice,
  deleteDevice,
} from "../../services/device";

const DevicesPage = (props) => {
  const [createDeviceFormOpen, setCreateFormOpen] = useState(false);
  const [createDeviceFormLoading, setCreateFormLoading] = useState(false);
  const [editDeviceFormOpen, setEditFormOpen] = useState(false);
  const [editDeviceFormLoading, setEditFormLoading] = useState(false);
  const [viewDeviceFormOpen, setViewFormOpen] = useState(false);
  const [editingDevice, setEditingDevice] = useState(null);
  const [viewingDevice, setViewingDevice] = useState(null);
  const [devices, setDevices] = useState(null);

  const handleCreateDevice = async (data) => {
    setCreateFormLoading(true);
    try {
      await createDevice(data);
      setCreateFormLoading(false);
      setCreateFormOpen(false);
      message.success("Device created successfully");
    } catch (error) {
      setCreateFormLoading(false);
      message.error(error.message);
    }
  };

  const handleUpdateDevice = async (data) => {
    setEditFormLoading(true);
    try {
      await editDevice(data);
      setEditFormLoading(false);
      setEditFormOpen(false);
      message.success("Device updated successfully");
    } catch (error) {
      setEditFormLoading(false);
      message.error(error.message);
    }
  };

  const handleDeleteDevice = async (id) => {
    try {
      await deleteDevice(id);
      message.success("Device deleted successfully");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleOpenEditDeviceForm = (id) => {
    const device = devices.find((device) => id == device.id);
    if (device) {
      setEditingDevice(device);
      setEditFormOpen(true);
    }
  };

  const handleOpenViewDeviceForm = (id) => {
    const device = devices.find((device) => id == device.id);
    if (device) {
      setViewingDevice(device);
      setViewFormOpen(true);
    }
  };

  useEffect(() => {
    const unsubscribe = getDevices((data) => {
      //console.log("GETING DEVICES DATA");
      setDevices(data);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Card
        title={
          <>
            Devices
            <Button
              onClick={() => {
                setCreateFormOpen(true);
              }}
              style={{ float: "right" }}
              size="small"
              type="primary"
            >
              Create
            </Button>
          </>
        }
      >
        <DevicesTable
          onDeviceView={handleOpenViewDeviceForm}
          onDeviceEdit={handleOpenEditDeviceForm}
          onDeviceDelete={handleDeleteDevice}
          data={devices}
        />
      </Card>
      <DeviceForm
        mode="create"
        open={createDeviceFormOpen}
        loading={createDeviceFormLoading}
        onClose={() => {
          setCreateFormOpen(false);
        }}
        onSubmit={handleCreateDevice}
      />

      <DeviceForm
        mode="edit"
        data={editingDevice}
        open={editDeviceFormOpen}
        loading={editDeviceFormLoading}
        onClose={() => {
          setEditFormOpen(false);
        }}
        onSubmit={handleUpdateDevice}
      />

      <DeviceForm
        mode="view"
        data={viewingDevice}
        loading={false}
        open={viewDeviceFormOpen}
        onClose={() => {
          setViewFormOpen(false);
        }}
      />
    </>
  );
};

export default DevicesPage;
