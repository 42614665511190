import React, { useState, useEffect } from "react";
import { Input, Form, Spin, Modal } from "antd";

const CreateGroupForm = ({ visible, onClose, onSubmit, loading }) => {
  const [form] = Form.useForm();

  const doSubmit = (values) => {
    onSubmit(values);
  };

  useEffect(() => {
    form.resetFields();
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      title="Create Group"
      getContainer={false}
      visible={visible}
      onOk={form.submit}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="createGroup"
          onFinish={doSubmit}
          layout="vertical"
          //   initialValues={formInitialValues()}
          hideRequiredMark
        >
          <Form.Item
            name="name"
            label="Enter group name"
            rules={[{ required: true, message: "Please enter the name" }]}
          >
            <Input autoFocus placeholder="Enter the name" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateGroupForm;
