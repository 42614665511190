import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Divider,
  Spin,
  message,
} from "antd";

import { MailOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";
import { loginWithEmail, loginWithGoogle } from "../services/auth";
import UserContext from "../helpers/userContext";

const LoginPage = ({ history, onComplete }) => {
  const [loading, setLoading] = useState(false);
  const { user, reloadUserDetails } = useContext(UserContext);

  const onFinish = async ({ email, password }) => {
    setLoading(true);
    try {
      const userData = await loginWithEmail({ email, password });
      //console.log("USER DATA>>", userData);
      reloadUserDetails();
      onComplete(userData);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const handleGoogleSignin = async () => {
    setLoading(true);
    try {
      const data = await loginWithGoogle();
      reloadUserDetails();
      onComplete(data);
      message.success("Login successful");
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center">
      <Col xs={24} md={14} lg={10} xl={8} xxl={6}>
        <Card title="Login to Continue">
          <Spin spinning={loading}>
            <Form
              name="auth"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="medium"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email." },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  autoComplete="username"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password." },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  autoComplete="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" noStyle valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Link className="login-form-forgot" to="/reset">
                  Forgot password
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Sign in
                </Button>
              </Form.Item>
              <Divider>OR</Divider>
              <Form.Item>
                <Button
                  danger
                  block
                  type="primary"
                  className="login-form-button"
                  icon={<GoogleOutlined />}
                  onClick={handleGoogleSignin}
                >
                  Sign in with Google
                </Button>
              </Form.Item>
              <Divider>
                <span>
                  Don't have an account? <Link to="/register">Sign up now</Link>
                </span>
              </Divider>
            </Form>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginPage;
