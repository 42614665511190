import { auth } from "./firebase";

export const updateProfile = (values) => {
  return new Promise((resolve, reject) => {
    let user = auth().currentUser;
    //console.log(user);
    user
      .updateProfile({ ...values })
      .then(resolve)
      .catch(reject);
  });
};

export const updatePassword = (currentPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    let user = auth().currentUser;
    reauthenticateUser(user, currentPassword)
      .then(() => {
        user.updatePassword(newPassword).then(resolve);
      })
      .catch(reject);
  });
};

const reauthenticateUser = (user, password) => {
  //console.log("Reauthenticating...");
  const credential = auth.EmailAuthProvider.credential(user.email, password);
  return user.reauthenticateWithCredential(credential);
};
