import React, { Component } from "react";
import { Form, Tag, Popover } from "antd";
import { CirclePicker } from "react-color";

const FormItem = Form.Item;

class ColorPicker extends Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ("color" in nextProps) {
      return {
        color: nextProps.color || null,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const color = props.color;
    this.state = {
      color: color || props.defaultColor || props.colors[0],
    };
  }

  handleColorChange = (value) => {
    const color = value.hex;

    this.triggerChange(color);
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    //console.log("Color Picker Rendered");
    const state = this.state;
    const color = state.color;
    //console.log(color);
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <CirclePicker
            color={color}
            onChangeComplete={(changedValues) => {
              this.handleColorChange(changedValues);
            }}
            colors={this.props.colors}
          ></CirclePicker>
        }
        // title={"Pick the color."}
      >
        <Tag style={{ float: "right", margin: 0 }} color={color}>
          {color}
        </Tag>
        {/* <Button>Color</Button> */}
      </Popover>
    );
  }
}

export default ColorPicker;
