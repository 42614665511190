import React from "react";
import { Col, Row } from "antd";
import Groups from "../components/groups";

const GroupsPage = () => {
  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col xs={24}>
        <Groups />
        <br />
      </Col>
    </Row>
  );
};

export default GroupsPage;
