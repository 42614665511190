import React, { useState, useEffect } from "react";
import {
  Drawer,
  Button,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  Form,
  Spin,
} from "antd";
import { supportedDeviceTypes } from "../../configs/supportedDeviceTypes";
import { supportedTraits } from "../../configs/supportedTraits";

const { Option, OptGroup } = Select;

const DeviceForm = ({ open, onClose, onSubmit, loading, mode, data }) => {
  const [form] = Form.useForm();
  const [deviceType, setDeviceType] = useState("");

  const deviceTypeOptions = () => {
    return supportedDeviceTypes.map((type) => (
      <Option key={type.deviceType} value={type.deviceType}>
        {type.name}
      </Option>
    ));
  };

  const doSubmit = (values) => {
    if ((mode = "edit" && data && data.id)) {
      onSubmit({ id: data.id, ...values });
    } else {
      onSubmit(values);
    }
  };
  const deviceTraitsOptions = () => {
    const deviceType = form.getFieldValue("type");

    let recommendedTraits = [];
    if (deviceType) {
      recommendedTraits = supportedDeviceTypes.filter(
        (type) => type.deviceType == deviceType
      )[0].recommendedTraits;
    }

    let recommendedTraitsOptions = recommendedTraits.map((trait) => (
      <Option key={trait} value={trait}>
        {supportedTraits.find((tr) => tr.trait == trait).name}
      </Option>
    ));

    if (recommendedTraits.length > 0) {
      recommendedTraitsOptions = [
        <OptGroup key="Recommended" lable="Recommended">
          {recommendedTraitsOptions}
        </OptGroup>,
      ];
    }

    let traits = supportedTraits.filter(
      (trait) => !recommendedTraits.includes(trait.trait)
    );

    let traitOptions = traits.map((trait) => (
      <Option key={trait.trait} value={trait.trait}>
        {trait.name}
      </Option>
    ));

    traitOptions = [
      ...recommendedTraitsOptions,
      <OptGroup key="Others" label="Other">
        {traitOptions}
      </OptGroup>,
    ];

    return traitOptions;
  };

  const handleDeviceTypeChange = (value) => {
    setDeviceType(value);
    form.resetFields(["traits"]);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
    setDeviceType(form.getFieldValue("type"));
  }, [open]);

  const formInitialValues = () => {
    if (data) {
      const { id, type, traits, name, deviceInfo } = data;
      return {
        id,
        type,
        traits,
        defaultNames: name.defaultNames,
        hwVersion: deviceInfo.hwVersion,
        swVersion: deviceInfo.swVersion,
        manufacturer: deviceInfo.manufacturer,
        model: deviceInfo.model,
      };
    }
    return {
      manufacturer: "Armath / Masis",
      model: "L001",
      hwVersion: "1.0",
      swVersion: "1.0",
    };
  };

  let allDisabled = false;
  if (mode == "view") {
    allDisabled = true;
  }

  return (
    <Drawer
      getContainer={false}
      forceRender
      title={
        mode == "edit"
          ? "Edit the device"
          : mode == "create"
          ? "Create a new device"
          : "View device"
      }
      width={720}
      onClose={onClose}
      visible={open}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        mode != "view" && (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        )
      }
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="createDevice"
          onFinish={doSubmit}
          layout="vertical"
          initialValues={formInitialValues()}
          hideRequiredMark
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  { required: true, message: "Please select a device type" },
                ]}
              >
                <Select
                  disabled={allDisabled}
                  onChange={handleDeviceTypeChange}
                  showSearch
                  value={deviceType}
                  placeholder="Select a device type"
                  optionFilterProp="children"
                  // onChange={onChange}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {deviceTypeOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="traits"
                label="Traits"
                rules={[{ required: true, message: "Please enter traits" }]}
              >
                <Select
                  disabled={allDisabled}
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select traits"
                  // defaultValue={["a10", "c12"]}
                  // onChange={handleChange}
                >
                  {deviceTraitsOptions()}
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="defaultNames"
                label="Default names"
                rules={[
                  { required: true, message: "Please enter default names" },
                ]}
              >
                <Select
                  disabled={allDisabled}
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Enter default names"
                  // onChange={handleChange}
                >
                  {/* <Option key="jack" value="jack">
                  Jack
                </Option>
                <Option key="lucy" value="lucy">
                  Lucy
                </Option>
                <Option key="tom" value="tom">
                  Tom
                </Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="manufacturer"
                label="Manufacturer"
                rules={[
                  { required: true, message: "Please enter the manufacturer" },
                ]}
              >
                <Input disabled placeholder="Enter the manufacturer"></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="model"
                label="Model"
                rules={[{ required: true, message: "Please enter the model" }]}
              >
                <Input
                  disabled={allDisabled}
                  placeholder="Enter the model"
                ></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="hwVersion"
                label="Hardware Version"
                rules={[
                  {
                    required: true,
                    message: "Please enter the hardware version",
                  },
                ]}
              >
                <Input
                  disabled={allDisabled}
                  placeholder="Enter the hardware version"
                ></Input>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="swVersion"
                label="Software Version"
                rules={[
                  {
                    required: true,
                    message: "Please enter the software version",
                  },
                ]}
              >
                <Input
                  disabled={allDisabled}
                  placeholder="Enter the software version"
                ></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default DeviceForm;
