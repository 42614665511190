import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Row, Statistic } from "antd";
import _ from "lodash";
import Clock from "./clock";
import UserContext from "../helpers/userContext";
import { getUserDevices } from "../services/device";

const successHomeIcon = "https://bit.ly/2TY9tK6";
const warningHomeIcon = "https://bit.ly/337dXCl";
const dangerHomeIcon = "https://bit.ly/2vVJqLB";

const Summary = () => {
  const [userDevices, setUserDevices] = useState([]);
  const { user } = useContext(UserContext);

  const getMaxTemperature = (sensors) => {
    const temperatures = sensors
      .filter((device) =>
        device.traits.includes("action.devices.traits.TemperatureControl")
      )
      .map((device) => device.states.temperatureAmbientCelsius);

    return _.max(temperatures);
  };

  const getMaxHumidity = (sensors) => {
    const humidities = sensors
      .filter((device) =>
        device.traits.includes("action.devices.traits.HumiditySetting")
      )
      .map((device) => device.states.humidityAmbientPercent);

    return _.max(humidities);
  };

  useEffect(() => {
    const unsubscribeFromDevices = getUserDevices(user.uid, (data) => {
      setUserDevices(data);
    });
    return unsubscribeFromDevices;
  }, []);

  const sensors = userDevices.filter(
    (device) => device.type == "action.devices.types.SENSOR"
  );
  //console.log(sensors);
  const maxTemp = getMaxTemperature(sensors);
  const maxHumidity = getMaxHumidity(sensors);
  const maxPressure = undefined;

  return (
    <Card title="Summary" extra={<Clock />}>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title="Condition"
            formatter={() => (
              <img style={{ height: "48px" }} src={successHomeIcon} />
            )}
            valueRender={0}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Temp (C)"
            value={isNaN(maxTemp) ? "N/A" : maxTemp}
            precision={2}
            suffix={isNaN(maxTemp) ? null : "°"}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Humidity"
            value={isNaN(maxHumidity) ? "N/A" : maxHumidity}
            suffix={isNaN(maxHumidity) ? null : "%"}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Pressure"
            value={isNaN(maxPressure) ? "N/A" : maxPressure}
            suffix={isNaN(maxPressure) ? null : "hPa"}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Summary;
