import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../helpers/userContext";
import {
  Card,
  Tabs,
  Col,
  Row,
  Menu,
  Layout,
  Breadcrumb,
  Typography,
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Checkbox,
  Button,
  autoComplete,
  Spin,
  message,
} from "antd";

import { updateProfile, updatePassword } from "../services/user";

const { Title } = Typography;

const AccountSettings = () => {
  const [loading, setLoading] = useState(false);
  const { user, reloadUserDetails } = useContext(UserContext);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    let { name, currentPassword, newPassword } = values;
    setLoading(true);
    try {
      if (newPassword) {
        if (currentPassword) {
          await updatePassword(currentPassword, newPassword);
        } else {
          throw { message: "Current Password is required." };
        }
      }
      await updateProfile({ displayName: name });
      message.success("Profile information updated.");
      reloadUserDetails();
      resetForm();
    } catch (error) {
      message.error(error.message);
    }

    setLoading(false);
  };

  const resetForm = () => {
    form.resetFields(["currentPassword", "newPassword", "confirm"]);
  };

  return (
    <div>
      <Title level={4}>Basic Settings</Title>
      <br />
      <Row>
        <Col xl={6} lg={12} md={18} xs={24}>
          <Spin spinning={loading}>
            <Form
              name="settings"
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              initialValues={{
                name: user.name || null,
                email: user.email || null,
                // currentPassword: null,
                newPassword: null,
                confirm: null,
              }}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: false,
                    message: "Please input your name.",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid Email.",
                  },
                  {
                    required: false,
                    message: "Email is required.",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                name="currentPassword"
                label="Current Password"
                rules={[
                  {
                    required: false,
                    message: "Password is required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: false,
                    message: "Password is required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm New Password"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: false,
                    message: "Please confirm your password.",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords don't match.");
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default AccountSettings;
