import React, { Component } from "react";
import { Card, Select } from "antd";

import UsersTable from "../../components/admin/usersTable";

class UsersPage extends Component {
  render() {
    return (
      <>
        <Card title="Users">
          <UsersTable />;
        </Card>
      </>
    );
  }
}

export default UsersPage;
