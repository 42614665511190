export const supportedTraits = [
  {
    trait: "action.devices.traits.Brightness",
    name: "Brightness",
    attributes: {
      commandOnlyBrightness: false,
    },
    states: {
      brightness: Number(),
    },
  },

  {
    trait: "action.devices.traits.ColorSetting",
    name: "ColorSetting",
    attributes: {
      colorModel: "rgb",
      commandOnlyColorSetting: false,
    },
    states: {
      ["color.spectrumRgb"]: Number(),
      // color: {
      //   spectrumRgb: Number
      // }
      // color: function(rgb) {
      //   this.spectrumRgb = parseInt(rgb);
      // }
    },
  },

  {
    trait: "action.devices.traits.OnOff",
    name: "OnOff",
    attributes: {
      commandOnlyOnOff: false,
    },
    states: {
      on: Boolean(),
    },
  },

  {
    trait: "action.devices.traits.SensorState",
    name: "SensorState",
    attributes: {
      sensorStatesSupported: [
        {
          name: "WaterLeak",
          descriptiveCapabilities: {
            availableStates: ["leak", "no leak", "unknown"],
          },
        },
      ],
    },
    states: {
      ["currentSensorStateData.name"]: String("Water Leak Sensor"),
      ["currentSensorStateData.currentSensorState"]: String("unknown"),

      // currentSensorStateData: function(name, currentSensorState) {
      //   this.name = name;
      //   this.currentSensorState = currentSensorState;
      // }
    },
  },

  {
    trait: "action.devices.traits.HumiditySetting",
    name: "HumiditySetting",
    attributes: {
      queryOnlyHumiditySetting: true,
    },
    states: {
      humidityAmbientPercent: Number(),
    },
  },

  {
    trait: "action.devices.traits.TemperatureControl",
    name: "TemperatureControl",
    attributes: {
      temperatureStepCelsius: 1.0,
      temperatureUnitForUX: "C",
      queryOnlyTemperatureControl: true,
    },
    states: {
      temperatureAmbientCelsius: Number(),
    },
  },

  {
    trait: "action.devices.traits.OpenClose",
    name: "OpenClose",
    attributes: {
      discreteOnlyOpenClose: true,
      queryOnlyOpenClose: true,
    },
    states: {
      openPercent: Number(),
    },
  },

  {
    trait: "action.devices.traits.ArmDisarm",
    name: "ArmDisarm",
    attributes: {},
    states: {
      isArmed: Boolean(),
    },
  },

  {
    trait: "action.devices.traits.StatusReport",
    name: "StatusReport",
    attributes: {},
    states: {
      blocking: Boolean(),
    },
  },
];
