import React, { useEffect, useState } from "react";

const Clock = () => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const currentTime = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      });
      setTime(currentTime);
    };
    //console.log("Starting Clock");
    updateTime();
    const timerInterval = setInterval(updateTime, 1000);

    return () => {
      clearInterval(timerInterval);
      //console.log("CLOSING CLOCK");
    };
  }, []);

  return time;
};

export default Clock;
