import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import UserContext from "../helpers/userContext";

import {
  HomeOutlined,
  BulbOutlined,
  ApartmentOutlined,
  SwitcherOutlined,
  SettingOutlined,
  DashboardOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = withRouter(({ collapsed, location }) => {
  const { user } = useContext(UserContext);
  const allDisabled = user ? false : true;

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" style={{ marginLeft: collapsed ? 24 : 16 }}>
        <img
          src="https://image.flaticon.com/icons/svg/1295/1295141.svg"
          alt=""
        />

        {!collapsed ? <h1>Smart Home</h1> : null}
      </div>

      <Menu
        theme="dark"
        mode="inline"
        open
        selectedKeys={[
          location.pathname,
          "/" + location.pathname.split("/")[1],
        ]}
        defaultOpenKeys={["/admin"]}
        // openKeys={["/" + location.pathname.split("/")[1]]}
      >
        <Menu.Item disabled={allDisabled} key="/">
          <HomeOutlined />
          <Link to="/">
            <span>Home</span>
          </Link>
        </Menu.Item>

        <Menu.Item disabled={allDisabled} key="/lights">
          <BulbOutlined />
          <Link to="/lights">
            <span>Lights</span>
          </Link>
        </Menu.Item>
        <Menu.Item disabled={allDisabled} key="/devices">
          <SwitcherOutlined />
          <Link to="/devices">
            <span>Devices</span>
          </Link>
        </Menu.Item>
        <Menu.Item disabled={allDisabled} key="/groups">
          <ApartmentOutlined />
          <Link to="/groups">
            <span>Groups</span>
          </Link>
        </Menu.Item>
        {user && user.isAdmin && (
          <SubMenu
            key="/admin"
            disabled={allDisabled}
            title={
              <>
                <DashboardOutlined />
                <span>Admin</span>
              </>
            }
          >
            <Menu.Item disabled={allDisabled} key="/admin/users">
              <Link to="/admin/users">
                <span>Users</span>
              </Link>
            </Menu.Item>
            <Menu.Item disabled={allDisabled} key="/admin/devices">
              <Link to="/admin/devices">
                <span>Devices</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        <Menu.Item disabled={allDisabled} key="/settings">
          <SettingOutlined />
          <Link to="/settings">
            <span>Settings</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
});

export default Sidebar;
