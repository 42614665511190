import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DeviceCard from "./deviceCard";
import AddDeviceForm from "./addDeviceForm";

import { Card, Tabs, Col, Row, message, Button, Empty } from "antd";

import UserContext from "../helpers/userContext";

import {
  getUserDevices,
  updateDeviceState,
  attachDeviceToUser,
  removeDeviceOfUser,
} from "../services/device";

import { getUserGroups } from "../services/group";

const { TabPane } = Tabs;

const Devices = (props) => {
  const { user } = useContext(UserContext);
  const [userDevices, setUserDevices] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [addDeviceFormOpen, setAddDeviceFormOpen] = useState(false);
  const [addDeviceFormLoading, setAddDeviceFormLoading] = useState(false);

  const handleDeviceStateChange = async (device) => {
    const { deviceId, newState } = device;
    //console.log(device);
    try {
      await updateDeviceState({ id: deviceId, newState });
      // setEditFormLoading(false);
      // setEditFormOpen(false);
      message.success("Device updated successfully");
    } catch (error) {
      // setEditFormLoading(false);
      message.error(error.message);
    }
  };

  const handleAddDevice = async ({ id }) => {
    //console.log("FORM SUBMITTED:ADDED");
    setAddDeviceFormLoading(true);
    try {
      await attachDeviceToUser({ userId: user.uid, deviceId: id });
      setAddDeviceFormOpen(false);
      message.success("Device added successfully");
    } catch (error) {
      message.error(error.message);
    } finally {
      setAddDeviceFormLoading(false);
    }
  };

  const handleRemoveDevice = async ({ id }) => {
    //console.log("FORM SUBMITTED:ADDED");
    try {
      await removeDeviceOfUser({ userId: user.uid, deviceId: id });
      message.success("Device removed successfully");
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const unsubscribeFromDevices = getUserDevices(user.uid, (data) => {
      setUserDevices(data);
    });

    const unsubscribeFromGroups = getUserGroups(user.uid, (data) => {
      setUserGroups(data);
    });

    return () => {
      unsubscribeFromDevices();
      unsubscribeFromGroups();
    };
  }, []);

  const filteredUserDevices = userDevices.filter((device) => {
    if (props.types == "lights") {
      return device.type == "action.devices.types.LIGHT";
    }

    return (
      props.types == "all" ||
      device.traits.includes("action.devices.traits.OnOff") ||
      device.type.includes("action.devices.types.SECURITYSYSTEM")
    );
  });

  const groups = [{ name: "All", id: null }, ...userGroups];

  return (
    <>
      <Card
        title={props.title}
        extra={
          props.editable && (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setAddDeviceFormOpen(true);
              }}
            >
              Add Device
            </Button>
          )
        }
      >
        {(filteredUserDevices.length && (
          <Tabs defaultActiveKey={"0"} onChange={(event) => {}} type="">
            {groups.map((group) => (
              <TabPane tab={group.name} key={group.id}>
                <Row gutter={[16, 16]}>
                  {filteredUserDevices
                    .filter(
                      (device) => group.id == null || group.id == device.groupId
                    )
                    .map((device) => {
                      return (
                        <Col key={device.id} xs={24} md={12} xl={12} xxl={6}>
                          <DeviceCard
                            customizable={props.editable}
                            onDeviceStateChange={handleDeviceStateChange}
                            onRemove={handleRemoveDevice}
                            device={device}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </TabPane>
            ))}
          </Tabs>
        )) || (
          <Empty description={"No Device"} image={Empty.PRESENTED_IMAGE_SIMPLE}>
            <Button
              onClick={() => {
                // setCreateGroupFormOpen(true);
              }}
              type="link"
            >
              <Link to="/devices">
                Go to <b> Devices </b> page to add a new device
              </Link>
            </Button>
          </Empty>
        )}
      </Card>
      <AddDeviceForm
        visible={addDeviceFormOpen}
        onClose={() => {
          setAddDeviceFormOpen(false);
        }}
        onSubmit={handleAddDevice}
        loading={addDeviceFormLoading}
      />
    </>
  );
};

export default Devices;
