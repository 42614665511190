import React, { useState, useEffect, useContext } from "react";

import Devices from "../components/devices";
import Summary from "../components/summary";
import RecentActivities from "../components/recentActivities";

import { Col, Row } from "antd";

const HomePage = () => {
  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col xs={24} lg={24} xl={15} xxl={17}>
        <Devices title="Devices" tabs={true} />
        <br />
      </Col>
      <Col xs={24} lg={24} xl={9} xxl={7}>
        <Summary />
        <br />
        <RecentActivities />
      </Col>
    </Row>
  );
};

export default HomePage;
