import React from "react";
import { Col, Row } from "antd";
import Devices from "../components/devices";

const DevicesPage = () => {
  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col xs={24}>
        <Devices title="Devices" types={"all"} editable tabs={false} />
        <br />
      </Col>
    </Row>
  );
};

export default DevicesPage;
