import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC67v7EJDYN_Gy-Bmd5EDXP2Lc-GANxHx8",
  authDomain: "smart-home-digicode-2020.firebaseapp.com",
  databaseURL: "https://smart-home-digicode-2020.firebaseio.com",
  projectId: "smart-home-digicode-2020",
  storageBucket: "smart-home-digicode-2020.appspot.com",
  messagingSenderId: "601571135119",
  appId: "1:601571135119:web:9ff88ca4621195463d2afa",
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().enablePersistence({ synchronizeTabs: true });

export const auth = firebase.auth;
export const db = firebase.firestore;
export const functions = firebase.app().functions("europe-west1");
