import React, { useState, useEffect } from "react";
import _ from "lodash";
// import { flatten } from "flat";
import ColorPicker from "./colorPicker";
import {
  Card,
  Col,
  Row,
  Slider,
  Switch,
  Descriptions,
  Form,
  Popover,
  Tag,
  Spin,
  Button,
} from "antd";

import generateIcon from "../utils/generateDeviceIcon";
import { DeleteOutlined } from "@ant-design/icons";

const statesToBeSwitch = ["on", "isArmed"];
const statesToBeSlider = ["brightness"];
const statesToBeColor = ["color.spectrumRgb"];

const { Meta } = Card;

const DeviceCard = ({
  device,
  onDeviceStateChange,
  customizable,
  onRemove,
}) => {
  //console.log("DEVICE TYPE:  ", device);

  const [color, setColor] = useState("#00000");
  const [loading, setLoading] = useState(false);
  const [deviceForm] = Form.useForm();

  const { name, type, traits, states } = device;
  //console.log("States", states);

  const handleChange = (changedValues, allValues) => {
    //console.log(allValues);
    onDeviceStateChange({ deviceId: device.id, newState: allValues });
  };

  const handleRemove = () => {
    onRemove({ id: device.id });
  };

  const mapStatesToComponents = (statesObject) => {
    const allDisabled = device.type == "action.devices.types.SENSOR";

    const states = Object.keys(statesObject);
    //console.log("States:", states);
    // //console.log(flatten);
    let switchComponent = states
      .filter((state) => statesToBeSwitch.includes(state))
      .map((state) => {
        return {
          state: state,
          component: (
            <Form.Item noStyle name={state}>
              <Switch
                disabled={allDisabled}
                style={{ float: "right" }}
                checked={statesObject[state]}
                size="small"
              />
            </Form.Item>
          ),
        };
      });

    let sliderComponent = states
      .filter((state) => statesToBeSlider.includes(state))
      .map((state) => {
        //console.log(statesObject[state]);
        return {
          state: state,
          component: (
            <>
              <Form.Item shouldUpdate noStyle name={state}>
                <Slider
                  defaultValue={statesObject[state]}
                  // value={statesObject[state]}
                  disabled={allDisabled}
                />
              </Form.Item>
              {/* <span>{statesObject[state]}</span> */}
            </>
          ),
        };
      });

    let colorComponent = states
      .filter((state) => statesToBeColor.includes(state))
      .map((state) => {
        //console.log("Slider rendered");
        return {
          state: state,
          component: (
            <>
              <Col span={12}>Color</Col>
              <Col span={12}>
                <Form.Item noStyle name={state}>
                  <ColorPicker
                    defaultColor={"#607d8b"}
                    color={statesObject[state]}
                    colors={[
                      "#f44336",
                      "#e91e63",
                      "#9c27b0",
                      "#673ab7",
                      "#607d8b",
                      "#795548",
                      "#ff5722",
                      "#ff9800",
                      "#ffc107",
                      "#ffeb3b",
                      "#cddc39",
                      "#8bc34a",
                      "#4caf50",
                      "#009688",
                      "#00bcd4",
                      "#2196f3",
                      "#3f51b5",
                    ]}
                  />
                </Form.Item>
              </Col>
            </>
          ),
        };
      });

    let desciprionComponent;
    if (_.get(colorComponent, "[0].component")) {
      desciprionComponent = null;
    } else if (_.get(sliderComponent, "[0].component")) {
      const stateName = sliderComponent[0].state;
      desciprionComponent = `${
        stateName[0].toUpperCase() + stateName.slice(1)
      }: ${statesObject[sliderComponent[0].state]}`;
    } else if (_.get(switchComponent, "[0].component")) {
      desciprionComponent = statesObject[switchComponent[0].state]
        ? "Turned On"
        : "Turned Off";
    } else {
      desciprionComponent = <span> </span>;
    }

    if (!_.get(sliderComponent, "[0].component")) {
      //console.log("Slider cmp is undefined");
      sliderComponent = [
        {
          component: (
            <Form.Item shouldUpdate={true} noStyle>
              <Slider
                // defaultValue={0}
                // value={0  }
                disabled={true}
              />
            </Form.Item>
          ),
        },
      ];
    }

    return {
      switchComponent: _.get(switchComponent, "[0].component"),
      sliderComponent: _.get(sliderComponent, "[0].component"),
      colorComponent: _.get(colorComponent, "[0].component"),
      desciprionComponent: desciprionComponent,
    };
  };
  // //console.log(mapStatesToComponents(states));

  const {
    switchComponent,
    sliderComponent,
    colorComponent,
    desciprionComponent,
  } = mapStatesToComponents(states);

  useEffect(() => {
    //console.log("Effect Called");
    // deviceForm.setFieldsValue();
  });

  //console.log("Device Rendered");
  return (
    <Form form={deviceForm} onValuesChange={handleChange}>
      <Card
        hoverable
        loading={false}
        actions={[
          <>
            <Row justify="space-around">
              <Col span={customizable ? 18 : 20}>{sliderComponent}</Col>

              {customizable && (
                <Col span={2}>
                  <Button
                    onClick={handleRemove}
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                    shape="circle"
                  ></Button>
                </Col>
              )}
            </Row>
          </>,
        ]}
      >
        <Meta
          avatar={generateIcon(type).primary}
          title={
            <>
              <Row justify="space-between" align="middle">
                <Col>{name.defaultNames[0]}</Col>
                <Col>{switchComponent}</Col>
              </Row>
            </>
          }
          description={
            <>
              <Row>{colorComponent || desciprionComponent}</Row>
            </>
          }
        />
      </Card>
    </Form>
  );
};

export default DeviceCard;
