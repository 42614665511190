import React, { useState, useEffect } from "react";
import AuthForm from "./components/authForm";
import AccessControlForm from "./components/accessControlForm";
import { message } from "antd";
import { parse } from "query-string";

import { authorize, getCurrentUser } from "./services/auth";

import UserContext from "./helpers/userContext";

const Authorization = ({ location }) => {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);

  const onAuth = async (userData) => {
    const idToken = await userData.user.getIdToken();
    setUser(userData.user);
    setIdToken(idToken);
  };

  const onAccess = async () => {
    try {
      const options = parse(location.search);
      const result = await authorize(idToken, options);
      //console.log("RESULT>>", result);
      if (result.status >= 400 && result.status < 500) throw result;
      if (result.status == 301) window.location = result.url;
    } catch (error) {
      message.error(error.message);
      // message.//console.log("ErrorAUTH>>", error);
    }
  };

  // useEffect(() => {
  //   getCurrentUser((user) => {
  //     setUser(user);
  //     console.log(user);
  //   });
  // }, []);

  return (
    <div>
      <UserContext.Provider
        value={{
          user: null,
          reloadUserDetails: () => {},
        }}
      >
        {!idToken && <AuthForm onComplete={onAuth} />}
        {idToken && <AccessControlForm user={user} onComplete={onAccess} />}
      </UserContext.Provider>
    </div>
  );
};

export default Authorization;
