import { db } from "./firebase";
import { supportedTraits } from "../configs/supportedTraits";
import _ from "lodash";
import { flatten, unflatten } from "flat";

const getStatesOfTraits = (traits) => {
  const traitsProps = supportedTraits.filter((trait) =>
    traits.includes(trait.trait)
  );
  const states = _.transform(
    traitsProps,
    (result, trait) => {
      result = _.merge(
        result,
        _.mapValues(trait.states, (state) => state.valueOf())
      );
    },
    {}
  );
  return unflatten(states);
};

export const createDevice = ({
  type,
  traits,
  defaultNames,
  manufacturer,
  model,
  hwVersion,
  swVersion,
}) => {
  const states = getStatesOfTraits(traits);
  const device = {
    userId: null,
    type,
    traits: traits.sort(),
    name: { defaultNames, name: "", nicknames: [] },
    deviceInfo: { manufacturer, model, hwVersion, swVersion },
    states,
  };

  return db().collection("devices").add(device);
};

export const editDevice = ({
  id,
  type,
  traits,
  defaultNames,
  manufacturer,
  model,
  hwVersion,
  swVersion,
}) => {
  const states = getStatesOfTraits(traits);
  const device = {
    userId: null,
    type,
    traits: traits.sort(),
    name: { defaultNames, name: "", nicknames: [] },
    deviceInfo: { manufacturer, model, hwVersion, swVersion },
    states,
  };

  return db().collection("devices").doc(id).update(device);
};

export const deleteDevice = (id) => {
  return db().collection("devices").doc(id).delete();
};

export const getDevices = (callback) => {
  const listener = db()
    .collection("devices")
    .onSnapshot((docs) => {
      const data = [];
      docs.forEach((doc) => {
        const document = { id: doc.id, ...doc.data() };
        data.push(document);
      });
      callback(data);
    });
  return listener;
};

export const getUserDevices = (id, callback) => {
  const listener = db()
    .collection("devices")
    .where("userId", "==", id)
    .onSnapshot((docs) => {
      const data = [];
      docs.forEach((doc) => {
        const document = { id: doc.id, ...doc.data() };
        document.states = flatten(document.states);
        data.push(document);
      });
      callback(data);
    });
  return listener;
};

export const attachDevicesToGroup = async (devices, groupId, uid) => {
  const response = await db()
    .collection("devices")
    .where("userId", "==", uid)
    .where("groupId", "==", groupId)
    .get();

  let batch = db().batch();
  response.docs.forEach((doc) => {
    const docRef = db().collection("devices").doc(doc.id);
    batch.update(docRef, { groupId: null });
  });

  await batch.commit();

  const promises = devices.map((device) =>
    db().collection("devices").doc(device).update({ groupId })
  );

  return Promise.all(promises);
};

export const updateDeviceState = ({ id, newState }) => {
  const updatedFields = flatten({ states: newState });
  return db().collection("devices").doc(id).update(updatedFields);
};

export const attachDeviceToUser = async ({ userId, deviceId }) => {
  let device = await db().collection("devices").doc(deviceId).get();
  if (device.exists) {
    return db().collection("devices").doc(deviceId).update({ userId });
  }
  throw { message: "Device Not Found" };
};

export const removeDeviceOfUser = ({ userId, deviceId }) => {
  return db().collection("devices").doc(deviceId).update({ userId: null });
};
