import { auth, functions } from "./firebase";
import { message } from "antd";

export const registerWithEmail = async ({
  email,
  password,
  firstname,
  lastname,
}) => {
  firstname = firstname.trim();
  lastname = lastname.trim();
  await auth().createUserWithEmailAndPassword(email, password);
  return auth().currentUser.updateProfile({
    displayName: `${firstname} ${lastname}`,
  });
};

export const loginWithEmail = ({ email, password }) => {
  return auth().signInWithEmailAndPassword(email, password);
};

export const loginWithGoogle = () => {
  const provider = new auth.GoogleAuthProvider();
  return auth().signInWithPopup(provider);
};

export const resetPassword = ({ email }) => {
  return auth().sendPasswordResetEmail(email);
};

export const logout = () => {
  return auth().signOut();
};

export const authorize = async (idToken, options) => {
  const auth = functions.httpsCallable("auth");
  const result = await auth({ idToken, ...options });
  return result.data;
};

export const getCurrentUser = (callback) => {
  auth().onAuthStateChanged(async (user) => {
    if (user) {
      const idTokenResult = await auth().currentUser.getIdTokenResult(true);
      const isAdmin = idTokenResult.claims.admin || false;
      const name = user.displayName;
      const email = user.email;
      const photoURL = user.photoURL;
      const emailVerified = user.emailVerified;
      const uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
      const data = {
        name,
        email,
        photoURL,
        emailVerified,
        isAdmin,
        uid,
      };
      callback(data);
    } else {
      callback(null);
    }
  });
};
