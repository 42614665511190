import React from "react";

const generateIcon = (deviceType) => {
  let logo = null;
  switch (deviceType) {
    case "action.devices.types.LIGHT":
      logo = {
        primary: (
          <span style={{ fontSize: "24px", color: "#ccc" }}>
            <i className="far fa-lightbulb"></i>
          </span>
        ),
        colored: (
          <span style={{ fontSize: "24px", color: "#faad14" }}>
            <i className="fas fa-lightbulb"></i>
          </span>
        ),
      };
      break;

    case "action.devices.types.OUTLET":
      logo = {
        primary: (
          <span style={{ fontSize: "24px", color: "#ccc" }}>
            <i className="fas fa-plug"></i>
          </span>
        ),
        colored: (
          <span style={{ fontSize: "24px", color: "#faad14" }}>
            <i className="fas fa-plug"></i>
          </span>
        ),
      };
    case "action.devices.types.SENSOR":
      logo = {
        primary: (
          <span style={{ fontSize: "24px", color: "#ccc" }}>
            <i className="fas fa-plug"></i>
          </span>
        ),
        colored: (
          <span style={{ fontSize: "24px", color: "#faad14" }}>
            <i className="fas fa-plug"></i>
          </span>
        ),
      };
    case "action.devices.types.SECURITYSYSTEM":
      logo = {
        primary: (
          <span style={{ fontSize: "24px", color: "#ccc" }}>
            <i className="fas fa-plug"></i>
          </span>
        ),
        colored: (
          <span style={{ fontSize: "24px", color: "#faad14" }}>
            <i className="fas fa-plug"></i>
          </span>
        ),
      };

      break;
    default:
      logo = {
        primary: (
          <span style={{ fontSize: "24px", color: "#ccc" }}>
            <i className="fas fa-question"></i>
          </span>
        ),
        colored: (
          <span style={{ fontSize: "24px", color: "#faad14" }}>
            <i className="fas fa-question"></i>
          </span>
        ),
      };
      break;
  }
  return logo;
};

export default generateIcon;
