import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Spin, Button, Tooltip, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Text } = Typography;

const DevicesTable = ({
  data,
  nested,
  onDeviceEdit,
  onDeviceView,
  onDeviceDelete,
}) => {
  const tableColumns = [
    {
      title: "ID",
      key: "id",
      render: ({ id, userId }) => (
        <Text type={userId ? "secondary" : "strong"}>{id}</Text>
      ),
    },
    {
      title: "Device Name",
      key: "name",
      render: ({ names, id }) =>
        names.map((name) => (
          <Tag
            onClick={() => {
              onDeviceView(id);
            }}
            key={name}
            color="default"
          >
            {name}
          </Tag>
        )),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        return type;
        // return type.split(".").pop();
        //   return type;
      },
    },
    {
      title: "Traits",
      dataIndex: "traits",
      key: "traits",
      render: (traits) =>
        traits.map((trait) => (
          <Tag key={trait} color="blue">
            {trait.split(".").pop()}
          </Tag>
        )),
    },
    {
      title: "Actions",
      key: "action",
      render: ({ id }) => {
        return (
          <span>
            <Tooltip title="Edit">
              <Button
                onClick={() => {
                  onDeviceEdit(id);
                }}
                icon={<EditOutlined />}
                type="link"
                shape="circle"
                style={{ marginRight: 8 }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                onClick={() => {
                  onDeviceDelete(id);
                }}
                icon={<DeleteOutlined />}
                type="link"
                danger
                shape="circle"
              />
            </Tooltip>
          </span>
        );
      },
    },
  ];

  const mapToViewModel = (data) => {
    //console.log("DEVICE DATA>>", data);
    return data.map((device) => {
      return {
        id: device.id,
        names: device.name.defaultNames,
        type: device.type.split(".").pop(),
        traits: device.traits,
        userId: device.userId,
      };
    });
  };

  const pagination = !nested ? { position: "bottom" } : false;
  return (
    <Spin spinning={!data}>
      <Table
        size="default"
        showHeader={true}
        tableLayout="auto"
        pagination={pagination}
        columns={tableColumns}
        rowKey="id"
        dataSource={data && data.length > 0 ? mapToViewModel(data) : null}
      />
    </Spin>
  );
};

export default DevicesTable;
